@if (detailPageData$ | async; as detailPageData) {
	<ng-container class="c-advice-detail">
		<vloket-advice-detail-page-header
			[pageTitle]="detailPageData.title"
			[heroImage]="detailPageData.heroImage.url"
			[keywords]="detailPageData.keywords"
			[minReadingTime]="detailPageData.readingTime"
			[lastUpdate]="detailPageData.lastUpdated"
		/>
		<div [innerHTML]="detailPageComponents$ | async"></div>
	</ng-container>
}
