import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { LayoutContainerComponent } from '@cjm/shared/ui/common';
import { transformJsonToObject } from '@cjm/v-loket/shared';

import { AdvicePageRoadmapEntity } from '../../../data';

@Component({
	selector: 'vloket-advice-detail-page-roadmap',
	templateUrl: './advice-detail-page-roadmap.component.html',
	styleUrls: ['./advice-detail-page-roadmap.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [LayoutContainerComponent]
})
export class AdviceDetailPageRoadmapComponent {
	@HostBinding('class.c-advice-detail-page-roadmap-block') private readonly rootClass: boolean = true;

	@Input({
		required: true,
		transform: (value: string) => {
			return transformJsonToObject<AdvicePageRoadmapEntity>(value);
		}
	})
	public data: AdvicePageRoadmapEntity;
}
