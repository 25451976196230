import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { LayoutContainerComponent } from '@cjm/shared/ui/common';
import { transformJsonToObject } from '@cjm/v-loket/shared';

import { AdvicePageLinksEntity } from '../../../data';
import { BackgroundVariants } from '../../interfaces';

@Component({
	selector: 'vloket-advice-detail-page-link-block',
	templateUrl: 'advice-detail-page-link-block.component.html',
	styleUrls: ['advice-detail-page-link-block.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [LayoutContainerComponent]
})
export class AdviceDetailPageLinkBlockComponent {
	@HostBinding('class.c-advice-detail-page-link-block') private readonly rootClass: boolean = true;

	@Input({
		required: true,
		transform: (value: string) => {
			return transformJsonToObject<AdvicePageLinksEntity>(value);
		}
	})
	public data: AdvicePageLinksEntity;

	public readonly backgroundVariants: typeof BackgroundVariants = BackgroundVariants;
}
