import {
	ComponentResultEntity,
	TekstResultEntity,
	ScheidingslijnResultEntity,
	AfbeeldingEnTekstResultEntity,
	LinksResultEntity,
	AanbevolenAdviezenResultEntity,
	StappenplanResultEntity,
	TegelsResultEntity,
	pageImageParser
} from '@cjm/v-loket/repositories';
import { TilesEntity } from '@cjm/v-loket/shared';

import {
	AdvicePageComponentEntity,
	AdvicePageDividerEntity,
	AdvicePageImageAndTextEntity,
	AdvicePageLinksEntity,
	AdvicePageRecommendationEntity,
	AdvicePageRoadmapEntity,
	AdvicePageTextEntity,
	ComponentTypes
} from '../interfaces';

export const parsePageComponents = (components: ComponentResultEntity[]): AdvicePageComponentEntity[] => {
	if (!components) return [];

	return components.map((component: ComponentResultEntity): AdvicePageComponentEntity => {
		switch (component['@type']) {
			case ComponentTypes.Text: {
				const comp = component as TekstResultEntity;

				return {
					type: ComponentTypes.Text,
					title: comp.titel,
					text: comp.tekst,
					variant: comp.variant
				} as AdvicePageTextEntity;
			}

			case ComponentTypes.Divider: {
				const comp = component as ScheidingslijnResultEntity;

				return { type: ComponentTypes.Divider, variant: comp.variant } as AdvicePageDividerEntity;
			}

			case ComponentTypes.ImageAndText: {
				const comp = component as AfbeeldingEnTekstResultEntity;

				return {
					type: ComponentTypes.ImageAndText,
					title: comp.titel,
					text: comp.tekst,
					image: pageImageParser(comp.afbeelding),
					variant: comp.variant
				} as AdvicePageImageAndTextEntity;
			}

			case ComponentTypes.Links: {
				const comp = component as LinksResultEntity;

				return {
					type: ComponentTypes.Links,
					title: comp.titel,
					variant: comp.variant,
					linkgroups: comp.linkgroepen.map((lg) => ({
						title: lg.titel,
						links: lg.links.map((links) => ({
							title: links.titel,
							url: links.link
						}))
					}))
				} as AdvicePageLinksEntity;
			}

			case ComponentTypes.Recommendation: {
				const comp = component as AanbevolenAdviezenResultEntity;

				return {
					type: ComponentTypes.Recommendation,
					title: comp.titel,
					variant: comp.variant,
					pages: comp.adviezen.map((page) => ({
						id: page.id,
						title: page.titel,
						description: page.omschrijving,
						heroImage: pageImageParser(page.afbeelding),
						keywords: page.trefwoorden.map((keyword) => ({ id: keyword.id, name: keyword.naam }))
					}))
				} as AdvicePageRecommendationEntity;
			}

			case ComponentTypes.Roadmap: {
				const comp = component as StappenplanResultEntity;

				return {
					type: ComponentTypes.Roadmap,
					title: comp.titel,
					text: comp.tekst,
					steps: comp.stappen.map((step) => ({ title: step.titel, text: step.tekst }))
				} as AdvicePageRoadmapEntity;
			}

			case ComponentTypes.Tiles: {
				const comp = component as TegelsResultEntity;

				return {
					type: ComponentTypes.Tiles,
					title: comp.titel,
					text: comp.tekst,
					variant: comp.variant,
					tiles: comp.tegels.map((tile) => ({ title: tile.titel, text: tile.tekst }))
				} as TilesEntity;
			}
		}
	});
};
