@if (data) {
	<cjm-layout-container
		class="c-advice-detail-page-split-block"
		[preventSpacing]="['top', 'bottom']"
		[class]="'c-advice-detail-page-split-block--' + splitVariants[data.variant]"
		[layoutType]="splitVariants[data.variant] && data?.image?.url ? 'split-equal' : 'full-width'"
		[asidePosition]="splitVariants[data.variant] === splitVariants.AfbeeldingLinks ? 'left' : 'right'"
	>
		@if (splitVariants[data.variant] === splitVariants.AfbeeldingLinks) {
			<div
				class="c-advice-detail-page-split-block__image"
				asideLeft
				[style.background-image]="'url(' + data?.image?.url + ')'"
			></div>
		}
		<div class="c-advice-detail-page-split-block__content u-wysiwyg" main>
			@if (data.title) {
				<h2 class="c-vloket-header c-vloket-header--h3">{{ data.title }}</h2>
			}
			@if (data.text) {
				<span [outerHTML]="data.text"></span>
			}
		</div>
		@if (splitVariants[data.variant] === splitVariants.AfbeeldingRechts) {
			<div
				class="c-advice-detail-page-split-block__image"
				asideRight
				[style.background-image]="'url(' + data?.image?.url + ')'"
			></div>
		}
	</cjm-layout-container>
}
