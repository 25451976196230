@if (data) {
	<cjm-layout-container
		class="c-advice-detail-page-link-block"
		layoutType="full-width"
		[class]="'c-advice-detail-page-link-block--' + backgroundVariants[data.variant]"
	>
		<ng-container main>
			@if (data.title) {
				<h2 class="c-vloket-header c-vloket-header--h3">
					<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
						{{ data.title }}
					</span>
				</h2>
			}
			@if (data.linkgroups) {
				<div class="c-advice-detail-page-link-block__link-group-container">
					@for (linkgroup of data.linkgroups; track linkgroup) {
						<ul class="c-advice-detail-page-link-block__link-group">
							@if (linkgroup.title) {
								<h3 class="c-vloket-header c-vloket-header--h4">{{ linkgroup.title }}</h3>
							}
							@for (links of linkgroup.links; track links) {
								<li class="c-advice-detail-page-link-block__link-item">
									<a class="c-advice-detail-page-link-block__link" [href]="links.url">
										{{ links.title }}
									</a>
								</li>
							}
						</ul>
					}
				</div>
			}
		</ng-container>
	</cjm-layout-container>
}
