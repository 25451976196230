@if (data) {
	<cjm-layout-container class="c-advice-detail-page-roadmap-block" layoutType="full-width">
		<ng-container main>
			@if (data.title) {
				<h2 class="c-vloket-header c-vloket-header--h3">
					{{ data.title }}
				</h2>
			}
			<span [outerHTML]="data.text"></span>
			@if (data.steps) {
				<ul class="c-advice-detail-page-roadmap-block__step-list">
					@for (step of data.steps; track step; let i = $index) {
						<li class="c-advice-detail-page-roadmap-block__step">
							@if (step?.title) {
								<div class="c-advice-detail-page-roadmap-block__step-header">
									<span class="c-advice-detail-page-roadmap-block__step-number">
										@if (i < 9) {
											{{ '0' + (i + 1) }}
										} @else {
											{{ i + 1 }}
										}
									</span>
									<h3 class="c-vloket-header c-vloket-header--h6">
										{{ step.title }}
									</h3>
								</div>
							}
							@if (step?.text) {
								<div class="u-wysiwyg" [innerHTML]="step.text"></div>
							}
						</li>
					}
				</ul>
			}
		</ng-container>
	</cjm-layout-container>
}
