<ng-container class="p-advice-overview">
	<vloket-page-header [assetUrl]="'/assets/img/' + headerImg">
		<h1 class="c-vloket-header c-vloket-header--h1">
			<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
				{{ i18nKeys.PageTitles.VLoket.Advice | translate }}
			</span>
		</h1>

		<p class="u-margin-bottom-lg">{{ i18nKeys.AdviceOverview.Intro | translate }}</p>
	</vloket-page-header>

	<cjm-layout-container class="p-advice-overview" layoutType="full-width" preventSpacing="bottom">
		<ng-container main>
			<ng-container *mediaQuery="mediaQueryMin.Tablet" [ngTemplateOutlet]="filterBarTmpl" />
			<ng-container *mediaQuery="mediaQueryMax.Tablet">
				<cjm-button
					class="u-margin-bottom-lg"
					type="button"
					[fullWidth]="true"
					[classes]="buttonClasses.LinkButtonOutline"
					[title]="
						(showFilterBar
							? i18nKeys.Actions.ThemesFilterToggle.Hide.Title
							: i18nKeys.Actions.ThemesFilterToggle.Show.Title
						) | translate
					"
					(handleClick)="handleFilterToggle()"
				>
					{{
						(showFilterBar
							? i18nKeys.Actions.ThemesFilterToggle.Hide.Text
							: i18nKeys.Actions.ThemesFilterToggle.Show.Text
						) | translate
					}}
				</cjm-button>

				@if (showFilterBar) {
					<ng-container [ngTemplateOutlet]="filterBarTmpl" />
				}
			</ng-container>
			<div class="p-advice-overview__results">
				@if (advicePagesTotalCount$ | async; as totalCount) {
					<cjm-results [subAmount]="advicePagesCountSubtotal$ | async" [amount]="totalCount" />
				}
				@if ((facetControl?.value | keyvalue)?.length > 0) {
					<cjm-button
						classes="{{ buttonClasses.LinkNoDecoration }} {{ buttonClasses.IconLeft }}"
						[title]="i18nKeys.Actions.ClearAll | translate"
						(click)="handleFilterClear()"
					>
						<fa-icon icon="fa-xmark" />
						{{ i18nKeys.Actions.ClearAll | translate }}
					</cjm-button>
				}
			</div>
		</ng-container>
	</cjm-layout-container>

	<cjm-layout-container layoutType="full-width">
		<cjm-content main [loading]="adviceOverviewLoading$ | async">
			@if (adviceOverviewPages$ | async; as pages) {
				<div class="p-advice-overview__pages-container">
					@for (page of pages; track page) {
						<vloket-advice-card [page]="page" />
					}
				</div>
			}
		</cjm-content>
	</cjm-layout-container>

	@if (advicePagesPagination$ | async; as pagination) {
		<cjm-layout-container
			class="p-advice-overview"
			layoutType="full-width"
			classesMain="c-advice-overview__pagination"
			alignItems="center"
			preventSpacing="bottom"
		>
			@if (pagination.totalPages > 1) {
				<cjm-pager
					main
					[current]="pagination.index"
					[max]="pagination.totalPages"
					(handleIndexChange)="handlePageChange($event)"
				/>
			}
		</cjm-layout-container>
	}
</ng-container>

<ng-template #filterBarTmpl>
	@if (facets$ | async; as facets) {
		<vloket-filter-buttons-bar [data]="$any(facets)" [formControl]="facetControl" />
	}
</ng-template>
