import { Injectable, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { StoreService } from '@studiohyperdrive/ngx-store';
import { ObservableBoolean, validateContent } from '@studiohyperdrive/rxjs-utils';
import { Observable, map, tap, shareReplay } from 'rxjs';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { BreadcrumbService } from '@cjm/shared/ui/breadcrumb';

import { I18nKeys } from '../../../i18n';
import { CustomComponentSelectors } from '../../interfaces';
import { actions, selectors } from '../../store';

import { AdviceDetailPageDataEntity, AdvicePageComponentEntity } from './advice-detail-page.facade.types';

@Injectable()
export class AdviceDetailPageFacade extends StoreService implements OnDestroy {
	private readonly adviceDetailPageStoreData$: Observable<AdviceDetailPageDataEntity> =
		this.selectFromStore<AdviceDetailPageDataEntity>(selectors.adviceDetailPageData).pipe(shareReplay());

	public readonly adviceDetailPageComponents$: Observable<SafeHtml> = this.adviceDetailPageStoreData$.pipe(
		validateContent(),
		map((pageData: AdviceDetailPageDataEntity) => {
			return this.renderPageComponents(pageData.components);
		})
	);

	public readonly adviceDetailPageLoading$: ObservableBoolean = this.selectLoadingFromStore(
		selectors.adviceDetailPageData
	);

	public readonly adviceDetailPageError$: ObservableBoolean = this.selectErrorFromStore(
		selectors.adviceDetailPageData
	);

	public readonly adviceDetailPageData$: Observable<AdviceDetailPageDataEntity> =
		this.adviceDetailPageStoreData$.pipe(
			validateContent(),
			tap((data: AdviceDetailPageDataEntity) => {
				this.breadcrumbService.setBreadcrumbs(
					[
						{
							routePath: [VLoketAppRoutePaths.Advice],
							title: this.i18nService.getTranslation(this.adviceI18nKeys.PageTitles.VLoket.Advice),
							allowNav: true
						},
						{
							routePath: [VLoketAppRoutePaths.Advice],
							title: data.title,
							mobileTitle: data.title.length > 30 ? this.adviceI18nKeys.PageTitles.DetailOnMobile : '',
							allowNav: false
						}
					],
					true,
					'absolute'
				);
			})
		);

	private readonly adviceI18nKeys: typeof I18nKeys = I18nKeys;

	constructor(
		protected readonly breadcrumbService: BreadcrumbService,
		protected readonly store: Store,
		private readonly i18nService: I18nService,
		private readonly sanitizer: DomSanitizer
	) {
		super(store);
	}

	public ngOnDestroy(): void {
		this.breadcrumbService.clearBreadcrumbs();
		this.store.dispatch(actions.adviceDetailPageData.clear());
	}

	public renderPageComponents = (components: AdvicePageComponentEntity[]): any => {
		const parser: DOMParser = new DOMParser();
		const body: Document = parser.parseFromString('<div class="c-advice-detail__content"></div>', 'text/html');
		const wrapper: HTMLElement = body.querySelector('.c-advice-detail__content');

		components.forEach((block: AdvicePageComponentEntity) => {
			const blockComponent: HTMLElement = body.createElement(CustomComponentSelectors[block.type]);

			blockComponent.setAttribute('data', JSON.stringify(block));
			wrapper.appendChild(blockComponent);
		});

		return this.sanitizer.bypassSecurityTrustHtml(body.documentElement.innerHTML);
	};
}
