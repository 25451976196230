import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableBoolean, validateContent } from '@studiohyperdrive/rxjs-utils';
import { catchError, map, take, of } from 'rxjs';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { AdvicePageResultEntity, pageImageParser } from '@cjm/v-loket/repositories';

import { AdviceDetailPageDataEntity } from '../../interfaces';
import { AdvicePageApiService } from '../../services';
import { actions } from '../../store';
import { parsePageComponents } from '../../utils';

export const adviceDetailPageResolver: ResolveFn<ObservableBoolean> = (
	route: ActivatedRouteSnapshot
): ObservableBoolean => {
	const store = inject(Store);
	const advicePageApiService = inject(AdvicePageApiService);
	const router = inject(Router);

	const id = route?.params?.id;

	if (!id) {
		router.navigate([VLoketAppRoutePaths.NotFound]);

		return of(false);
	}

	return dispatchDataToStore(
		actions.adviceDetailPageData,
		advicePageApiService.getAdvicePage(id).pipe(
			validateContent({ strict: true }),
			map(
				(response: AdvicePageResultEntity): AdviceDetailPageDataEntity => ({
					id: response.id,
					title: response.titel,
					description: response.omschrijving,
					readingTime: response.leestijd,
					lastUpdated: response.bijgewerkt,
					heroImage: pageImageParser(response.afbeelding),
					keywords: response.trefwoorden.map((keyword) => ({ id: keyword.id, name: keyword.naam })),
					components: parsePageComponents(response.componenten)
				})
			)
		),
		store
	).pipe(
		take(1),
		map(() => true),
		catchError(() => {
			router.navigate([VLoketAppRoutePaths.NotFound]);

			return of(false);
		})
	);
};
